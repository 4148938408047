$block: ".header-hamburger";
#{$block} {
    &__button {
        width: 28px;
        height: 28px;

        display: block;
        position: relative;

        border: 0;
        background-color: transparent;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:before,
        &:after,
        span {
            width: 100%;
            height: 3px;

            left: 0;
            position: absolute;

            background-color: $color-white;
            transition: all 0.25s ease-in-out;

            content: " ";
        }

        &:before {
            top: 3px;
            transform-origin: top left;
        }

        span {
            top: 12px;
        }

        &:after {
            bottom: 3px;
            transform-origin: bottom left;
        }



        &--is-active {
            &:before {
                transform: rotate(44deg) translate(0px, -4px) scale(1.27);
            }

            &:after {
                transform: rotate(-45deg) translate(0px, 4px) scale(1.28);
            }

            span {
                opacity: 0;
            }
        }
    }
}