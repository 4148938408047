$block: ".field";
#{$block} {
    position: relative;

    &__input {
        width: 100%;

        padding: 8px 0;

        display: block;

        color: $color-black-russian;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;

        border: 0;
        border-bottom: 1px solid $color-lima;
        background-color: transparent;

        &::-moz-placeholder {
            color: #c4c4c4;
        }

        &::-webkit-input-placeholder {
            color: #c4c4c4;
        }

        &:-ms-input-placeholder {
            color: #c4c4c4;
        }

        &::-ms-input-placeholder {
            color: #c4c4c4;
        }

        &::placeholder {
            color: #c4c4c4;
        }

        &:focus {
            outline: none;
        }
    }

    &__error {
        display: none;

        top: 100%;
        position: absolute;

        color: $color-alizarin;
        font-size: 16px;
        font-weight: 400;
        font-family: Play;
        line-height: 20px;
    }

    &--error {
        #{$block} {
            &__input {
                border-color: $color-alizarin;
            }

            &__error {
                display: block;
            }
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__input {
            font-size: 24px;
            line-height: 28px;
        }

        &__error {
            font-size: 18px;
            line-height: 22px;
        }
    }
}