$block: ".main-support";
#{$block} {
    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
    }

    &__text {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }
}

@media all {
    #{$block} {
        &__text {
            margin-right: 8px;
        }

        &__mincifri {
            width: 84px;
            margin-right: 16px;
        }

        &__rfrit {
            width: 104px;
        }
    }
}

@media all and (max-width: 639px) {
    #{$block} {
        &__wrapper {
            justify-content: center;
        }

        &__text {
            flex: 0 0 100%;
            margin-bottom: 4px;

            text-align: center;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__text {
            margin-right: 16px;

            font-size: 28px;
            font-weight: 700;
            line-height: 30px;
        }

        &__mincifri {
            width: 170px;
        }

        &__rfrit {
            width: 230px;
        }
    }
}