@import "./menu/menu.scss";
@import "./hamburger/hamburger.scss";

$block: ".header";
#{$block} {
    background-color: $color-black-russian;

    &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }
}

@media all {
    #{$block} {
        &__wrapper {
            padding: 8px 16px;
        }

        &__logo {
            height: 32px;
            width: auto;
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__wrapper {
            padding: 12px 32px;
        }

        &__logo {
            height: 40px;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__wrapper {
            padding: 16px 0;
        }

        &__logo {
            height: 64px;
        }

        &__hamburger {
            display: none;
        }
    }
}