$block: ".analytics";

#{$block} {
    &__img {
        margin: 0 auto;
    }
}

@media all {
    #{$block} {
        &__img {
            max-width: 70%;
            margin-bottom: 16px;
        }

        &__item {
            margin-bottom: 16px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__img {
            margin-bottom: 24px;
        }

        &__list {
            margin: -16px;

            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        &__item {
            flex: 0 0 300px;

            margin: 16px !important;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__img {
            margin-bottom: 32px;
        }

        &__list {
            justify-content: space-between;
        }

        &__item {
            flex: 0 0 calc(33.3333333% - 32px);
        }
    }
}


