* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: inherit;
    }
}

body {
    font-family: Play, 'sans-serif';
    word-wrap: break-word;
    word-break: break-word;
    background-color: $color-white;
}

.wrapper {
    max-width: 1344px;
    margin: 0 auto;

    @media all and (min-width: 1440px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none !important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0,0,0,0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
}

.page__header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    position: fixed;
}