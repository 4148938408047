$block: ".about-item";

#{$block} {
    &__icon {
        max-width: 32px;
        max-height: 32px;

        margin: 0 auto 8px auto;
        display: block;
    }

    &__item {
        text-align: center;
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        padding-left: 64px;
        position: relative;

        &__icon {
            max-width: 48px;
            max-height: 48px;

            left: 0;
            top: 0;
            position: absolute;
        }

        &__item {
            text-align: left;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        padding-left: 96px;

        &__icon {
            max-width: 64px;
            max-height: 64px;
        }
    }
}
