* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: Circe, "sans-serif";
}

/* Libraries, which is used in current project. */
/* Libraries, which is used in current project. */
/* Some styles, which is used for correct sprite-generation, vars, mixins and etc */
/* Please, do not edit with file! */
/* This file can be overwritten by update-project! */
/* Mixins, which are specific for current project */
/* Mixins and vars for sprite generation */
/* Scss for used fonts */
@font-face {
  font-family: "Play";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/play-v12-latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/play-v12-latin_cyrillic-regular.woff") format("woff");
}
@font-face {
  font-family: "Play";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/play-v12-latin_cyrillic-700.woff2") format("woff2"), url("../fonts/play-v12-latin_cyrillic-700.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/roboto-v29-latin_cyrillic-regular.woff2") format("woff2"), url("../fonts/roboto-v29-latin_cyrillic-regular.woff") format("woff");
}
/* Vars, which are specific for current project */
/* Styles for UI-elements */
/* Common styles for current project */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: Play, "sans-serif";
  word-wrap: break-word;
  word-break: break-word;
  background-color: #fff;
}

.wrapper {
  max-width: 1344px;
  margin: 0 auto;
}
@media all and (min-width: 1440px) {
  .wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
}

.page__header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  position: fixed;
}

/* Plugins, which is used in current project. */
/* Components, which is used in current project. */
.link__item {
  padding: 8px 32px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  background-color: #8bb722;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.link__item:hover {
  background-color: #a8d933;
}

@media all and (min-width: 768px) {
  .link__item {
    border-radius: 8px;
  }
}
@media all and (min-width: 1440px) {
  .link__item {
    font-size: 36px;
    line-height: 60px;
  }
}
.pic {
  display: block;
  max-width: 100%;
}
.pic__img {
  display: inherit;
  max-width: inherit;
}

.header-menu__item {
  list-style-type: none;
}
.header-menu__link {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
}
.header-menu__phone {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.25s ease-in-out;
}
.header-menu__phone:hover {
  border-color: #fff;
}
.header-menu__phone:focus {
  outline-offset: 4px;
  outline: 1px dashed #fff;
}
.header-menu__email {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.25s ease-in-out;
}
.header-menu__email:hover {
  border-color: #fff;
}
.header-menu__email:focus {
  outline-offset: 4px;
  outline: 1px dashed #fff;
}

@media all and (max-width: 767px) {
  .header-menu {
    padding: 32px 16px;
    top: 48px;
  }
}
@media all and (min-width: 768px) and (max-width: 1439px) {
  .header-menu {
    padding: 64px 32px;
    top: 64px;
  }
}
@media all and (max-width: 1439px) {
  .header-menu {
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    position: fixed;
    transform: translateX(-100%);
    background-color: #1a1c1f;
    transition: all 0.5s ease-in-out;
  }
  .header-menu--is-active {
    display: block;
    transform: translateX(0);
  }
  .header-menu__list {
    margin-bottom: 32px;
  }
  .header-menu__item {
    margin-bottom: 20px;
  }
  .header-menu__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 1440px) {
  .header-menu__row {
    display: none;
  }
  .header-menu__list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  .header-menu__item {
    margin-right: 32px;
  }
  .header-menu__item:last-of-type {
    margin-right: 0;
  }
  .header-menu__link {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    line-height: 21px;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
  }
  .header-menu__link:hover {
    color: #8bb722;
  }
}
.header-hamburger__button {
  width: 28px;
  height: 28px;
  display: block;
  position: relative;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.header-hamburger__button:focus {
  outline: none;
}
.header-hamburger__button:before, .header-hamburger__button:after,
.header-hamburger__button span {
  width: 100%;
  height: 3px;
  left: 0;
  position: absolute;
  background-color: #fff;
  transition: all 0.25s ease-in-out;
  content: " ";
}
.header-hamburger__button:before {
  top: 3px;
  transform-origin: top left;
}
.header-hamburger__button span {
  top: 12px;
}
.header-hamburger__button:after {
  bottom: 3px;
  transform-origin: bottom left;
}
.header-hamburger__button--is-active:before {
  transform: rotate(44deg) translate(0px, -4px) scale(1.27);
}
.header-hamburger__button--is-active:after {
  transform: rotate(-45deg) translate(0px, 4px) scale(1.28);
}
.header-hamburger__button--is-active span {
  opacity: 0;
}

.header {
  background-color: #1a1c1f;
}
.header__wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

@media all {
  .header__wrapper {
    padding: 8px 16px;
  }
  .header__logo {
    height: 32px;
    width: auto;
  }
}
@media all and (min-width: 768px) {
  .header__wrapper {
    padding: 12px 32px;
  }
  .header__logo {
    height: 40px;
  }
}
@media all and (min-width: 1440px) {
  .header__wrapper {
    padding: 16px 0;
  }
  .header__logo {
    height: 64px;
  }
  .header__hamburger {
    display: none;
  }
}
.section--gray {
  background-color: #f2f2f2;
}
.section__title {
  color: #1a1c1f;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}
.section__pic img {
  margin: 0 auto;
}

@media all {
  .section__wrapper {
    padding: 32px 16px;
  }
  .section__title {
    margin-bottom: 16px;
  }
  .section__pic img {
    max-width: 70%;
    margin-bottom: 16px;
  }
}
@media all and (min-width: 768px) {
  .section__wrapper {
    padding: 32px;
  }
  .section__title {
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }
  .section__pic img {
    margin-bottom: 24px;
  }
}
@media all and (min-width: 1440px) {
  .section__wrapper {
    padding: 48px 0;
  }
  .section__title {
    margin-bottom: 48px;
    font-size: 52px;
    line-height: 60px;
  }
  .section__pic img {
    margin-bottom: 32px;
  }
}
.list__item {
  list-style-type: none;
}

.item__title {
  color: #1a1c1f;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}
.item__description {
  color: #1a1c1f;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, "sans-serif";
  line-height: 18px;
}
.item__item {
  position: relative;
  color: #1a1c1f;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, "sans-serif";
  line-height: 18px;
  list-style-type: none;
}
.item__item:after {
  width: 4px;
  height: 4px;
  left: 16px;
  top: 6px;
  position: absolute;
  border-radius: 2px;
  background-color: #1a1c1f;
  content: " ";
}

@media all {
  .item__title {
    margin-bottom: 8px;
  }
  .item__item {
    padding-left: 32px;
    margin-bottom: 8px;
  }
  .item__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .item__title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 26px;
  }
}
@media all and (min-width: 1440px) {
  .item__title {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 30px;
  }
  .item__description, .item__item {
    font-size: 22px;
    line-height: 26px;
  }
}
.main-support__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.main-support__text {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media all {
  .main-support__text {
    margin-right: 8px;
  }
  .main-support__mincifri {
    width: 84px;
    margin-right: 16px;
  }
  .main-support__rfrit {
    width: 104px;
  }
}
@media all and (max-width: 639px) {
  .main-support__wrapper {
    justify-content: center;
  }
  .main-support__text {
    flex: 0 0 100%;
    margin-bottom: 4px;
    text-align: center;
  }
}
@media all and (min-width: 1440px) {
  .main-support__text {
    margin-right: 16px;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
  }
  .main-support__mincifri {
    width: 170px;
  }
  .main-support__rfrit {
    width: 230px;
  }
}
.main {
  background-color: #1b2b43;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-image: url("../img/content/main@2x.jpg");
  background-image: image-set(url("../img/content/main.jpg") 1x, url("../img/content/main@2x.jpg") 2x);
}
.main__wrapper {
  position: relative;
}
.main__pic img {
  margin: 0 auto 16px auto;
}
.main__text {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.main__title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
}
.main__title span {
  display: block;
  font-size: 28px;
  line-height: 32px;
}
.main__label {
  padding: 16px 4px 8px 4px;
  display: block;
  top: 0;
  left: 32px;
  position: absolute;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #8bb722;
}
.main__phone {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
}
.main__email {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.main__link {
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.25s ease-in-out;
}
.main__link:hover {
  border-color: #fff;
}
.main__link:focus {
  outline-offset: 4px;
  outline: 1px dashed #fff;
}

@media all {
  .main {
    padding-top: 48px;
  }
  .main__wrapper {
    padding: 32px 16px;
  }
  .main__text1 {
    margin-bottom: 8px;
  }
  .main__title {
    margin-bottom: 8px;
  }
  .main__text2 {
    margin-bottom: 12px;
  }
  .main__support {
    margin-bottom: 16px;
  }
  .main__button {
    text-align: center;
  }
  .main__button a {
    min-width: 280px;
  }
}
@media all and (max-width: 767px) {
  .main__pic2 {
    display: none;
  }
  .main__label {
    display: none;
  }
  .main__row {
    display: none;
  }
}
@media all and (min-width: 768px) {
  .main {
    padding-top: 64px;
  }
  .main__wrapper {
    padding: 32px;
  }
  .main__pic {
    display: none;
  }
  .main__pic2 {
    width: 33.3333333%;
    display: block;
    bottom: 32px;
    right: 48px;
    position: absolute;
  }
  .main__label {
    display: block;
  }
  .main__title {
    margin-top: 48px;
    font-size: 52px;
    line-height: 45px;
    text-align: left;
  }
  .main__title span {
    font-size: 36px;
    line-height: 40px;
  }
  .main__text1 {
    max-width: 384px;
    text-align: left;
    top: 0;
    left: 108px;
    position: absolute;
  }
  .main__text2 {
    display: none;
  }
  .main__button {
    margin-bottom: 32px;
    text-align: left;
  }
  .main__phone {
    margin-bottom: 4px;
  }
}
@media all and (min-width: 1440px) {
  .main {
    padding-top: 96px;
  }
  .main__wrapper {
    padding: 48px 0;
  }
  .main__title {
    margin-top: 120px;
    margin-bottom: 32px;
    font-size: 90px;
    line-height: 94px;
  }
  .main__title span {
    font-size: inherit;
    line-height: inherit;
  }
  .main__support {
    margin-bottom: 40px;
  }
  .main__label {
    padding: 24px 12px 16px;
    left: 0;
    font-size: 41px;
    font-weight: 700;
    line-height: 56px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .main__text1 {
    max-width: 696px;
    top: 32px;
    left: 180px;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
  }
  .main__pic2 {
    width: 588px;
    right: 0;
    top: calc(50% - 258px);
  }
  .main__phone {
    font-size: 52px;
    line-height: 60px;
  }
  .main__email {
    font-size: 36px;
    line-height: 60px;
  }
}
.main-after {
  background-color: #8bb722;
}
@media all and (max-width: 767px) {
  .main-after {
    display: none;
  }
}
@media all and (min-width: 768px) {
  .main-after {
    padding: 8px 32px 12px 32px;
  }
  .main-after__item {
    display: none;
  }
  .main-after__item--key {
    display: block;
  }
  .main-after__item--key .item__title {
    display: none;
  }
  .main-after__item--key .item__title,
.main-after__item--key .item__item,
.main-after__item--key .item__description {
    color: #fff;
    text-align: center;
  }
}
@media all and (min-width: 1440px) {
  .main-after {
    padding: 40px 0;
  }
  .main-after__list {
    margin: 0 -16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .main-after__item {
    margin: 0 16px;
    flex: 0 0 calc(50% - 32px);
    display: block;
  }
  .main-after__item .item__title,
.main-after__item .item__item,
.main-after__item .item__description {
    display: block;
    color: #fff;
  }
  .main-after__item .item__item:after {
    background-color: #fff;
  }
  .main-after__item--key .item__title,
.main-after__item--key .item__description {
    text-align: left;
  }
}
.about-item__icon {
  max-width: 32px;
  max-height: 32px;
  margin: 0 auto 8px auto;
  display: block;
}
.about-item__item {
  text-align: center;
}

@media all and (min-width: 768px) {
  .about-item {
    padding-left: 64px;
    position: relative;
  }
  .about-item__icon {
    max-width: 48px;
    max-height: 48px;
    left: 0;
    top: 0;
    position: absolute;
  }
  .about-item__item {
    text-align: left;
  }
}
@media all and (min-width: 1440px) {
  .about-item {
    padding-left: 96px;
  }
  .about-item__icon {
    max-width: 64px;
    max-height: 64px;
  }
}
@media all {
  .about__item {
    margin-bottom: 16px;
  }
  .about__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .about__list {
    margin: -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .about__item {
    flex: 0 0 300px;
    margin: 16px !important;
  }
}
@media all and (min-width: 1440px) {
  .about__list {
    margin: 0 -24px;
    justify-content: space-between;
  }
  .about__item {
    flex: 0 0 calc(50% - 48px);
    margin: 24px !important;
  }
}
@media all {
  .how__img {
    margin-bottom: 16px;
  }
  .how__img img {
    margin: 0 auto;
  }
  .how__item {
    margin-bottom: 24px;
    text-align: center;
  }
  .how__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .how__list {
    margin: -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .how__item {
    flex: 0 0 300px;
    margin: 16px !important;
    text-align: left;
  }
  .how__img img {
    margin: 0;
  }
}
@media all and (min-width: 1440px) {
  .how__img {
    margin-bottom: 32px;
  }
  .how__list {
    justify-content: space-between;
  }
  .how__item {
    flex: 0 0 calc(33.3333333% - 32px);
  }
}
@media all {
  .monitoring__item {
    margin-bottom: 16px;
  }
  .monitoring__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .monitoring__list {
    margin: -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .monitoring__item {
    flex: 0 0 300px;
    margin: 16px !important;
  }
}
@media all and (min-width: 1440px) {
  .monitoring__list {
    justify-content: space-between;
  }
  .monitoring__item {
    flex: 0 0 calc(33.3333333% - 32px);
  }
}
.analytics__img {
  margin: 0 auto;
}

@media all {
  .analytics__img {
    max-width: 70%;
    margin-bottom: 16px;
  }
  .analytics__item {
    margin-bottom: 16px;
  }
  .analytics__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .analytics__img {
    margin-bottom: 24px;
  }
  .analytics__list {
    margin: -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .analytics__item {
    flex: 0 0 300px;
    margin: 16px !important;
  }
}
@media all and (min-width: 1440px) {
  .analytics__img {
    margin-bottom: 32px;
  }
  .analytics__list {
    justify-content: space-between;
  }
  .analytics__item {
    flex: 0 0 calc(33.3333333% - 32px);
  }
}
@media all {
  .map__item {
    margin-bottom: 16px;
  }
  .map__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .map__list {
    margin: -16px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .map__item {
    flex: 0 0 300px;
    margin: 16px !important;
  }
}
@media all and (min-width: 1440px) {
  .map__list {
    justify-content: space-between;
  }
  .map__item {
    flex: 0 0 calc(33.3333333% - 32px);
  }
}
@media all {
  .notification__item {
    margin-bottom: 16px;
  }
  .notification__item:last-of-type {
    margin-bottom: 0;
  }
}
@media all and (min-width: 768px) {
  .notification__list {
    margin: 0 -16px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  .notification__item {
    flex: 0 0 300px;
    margin: 0 16px !important;
  }
}
@media all and (min-width: 1440px) {
  .notification__list {
    margin: 0 -48px;
  }
  .notification__item {
    flex: 0 0 552px;
    margin: 0 48px !important;
  }
}
.field {
  position: relative;
}
.field__input {
  width: 100%;
  padding: 8px 0;
  display: block;
  color: #1a1c1f;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  border: 0;
  border-bottom: 1px solid #8bb722;
  background-color: transparent;
}
.field__input::-moz-placeholder {
  color: #c4c4c4;
}
.field__input::-webkit-input-placeholder {
  color: #c4c4c4;
}
.field__input:-ms-input-placeholder {
  color: #c4c4c4;
}
.field__input::-ms-input-placeholder {
  color: #c4c4c4;
}
.field__input::placeholder {
  color: #c4c4c4;
}
.field__input:focus {
  outline: none;
}
.field__error {
  display: none;
  top: 100%;
  position: absolute;
  color: #db2c30;
  font-size: 16px;
  font-weight: 400;
  font-family: Play;
  line-height: 20px;
}
.field--error .field__input {
  border-color: #db2c30;
}
.field--error .field__error {
  display: block;
}

@media all and (min-width: 768px) {
  .field__input {
    font-size: 24px;
    line-height: 28px;
  }
  .field__error {
    font-size: 18px;
    line-height: 22px;
  }
}
.form__title {
  color: #1a1c1f;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}
.form__title span {
  display: block;
  color: #8bb722;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
}
.form__description, .form__note {
  color: #1a1c1f;
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  line-height: 18px;
}
.form__button {
  padding: 8px 24px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  border: 0;
  border-radius: 4px;
  background-color: #8bb722;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
.form__button:hover {
  background-color: #a8d933;
}

@media all {
  .form__title {
    margin-bottom: 16px;
  }
  .form__description {
    margin-bottom: 32px;
  }
  .form__note {
    margin-bottom: 32px;
  }
  .form__fields {
    margin-bottom: 32px;
  }
  .form__field {
    margin-bottom: 24px;
  }
  .form__field:last-of-type {
    margin-bottom: 0;
  }
  .form__buttons {
    display: flex;
    justify-content: center;
  }
  .form__button {
    min-width: 280px;
  }
}
@media all and (min-width: 768px) {
  .form__title {
    color: #8bb722;
    font-size: 52px;
    line-height: 45px;
    text-align: center;
  }
  .form__title span {
    display: inline;
    font-size: inherit;
    line-height: inherit;
  }
  .form__description {
    width: 480px;
    margin: 0 auto 48px auto;
    line-height: 20px;
    text-align: center;
  }
  .form__field {
    margin-bottom: 32px;
  }
  .form__note {
    line-height: 20px;
  }
}
@media all and (min-width: 1440px) {
  .form__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  .form__col {
    flex: 0 0 504px;
    margin: 0 48px;
  }
  .form__title {
    margin-bottom: 48px;
    color: #1a1c1f;
    font-size: 72px;
    line-height: 72px;
  }
  .form__title span {
    display: block;
    color: #8bb722;
    font-size: 100px;
    line-height: 93px;
  }
  .form__description {
    margin-bottom: 0;
    font-size: 24px;
    line-height: 44px;
    text-align: left;
  }
  .form__fields {
    margin-top: 56px;
  }
  .form__buttons {
    justify-content: flex-start;
  }
  .form__button {
    min-width: 330px;
    padding: 16px 32px;
    font-size: 36px;
    line-height: 48px;
    border-radius: 8px;
  }
}
.thanks__title,
.error__title {
  margin-bottom: 8px;
  color: #8bb722;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}
.thanks__description,
.error__description {
  color: #1a1c1f;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

@media all and (min-width: 768px) {
  .thanks__title,
.error__title {
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 40px;
  }
}
@media all and (min-width: 1440px) {
  .thanks__title,
.error__title {
    font-size: 48px;
    line-height: 56px;
  }
  .thanks__description,
.error__description {
    font-size: 22px;
    line-height: 26px;
  }
}
.error__title {
  color: #FF294F !important;
}

.footer {
  background-color: #8bb722;
}
.footer__wrapper {
  padding: 32px 16px 64px;
}
.footer__title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-transform: uppercase;
}
.footer__title span {
  display: block;
  font-size: 28px;
  line-height: 34px;
}
.footer__text {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.footer__phone {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.25s ease-in-out;
}
.footer__phone:hover {
  border-color: #fff;
}
.footer__phone:focus {
  outline-offset: 4px;
  outline: 1px dashed #fff;
}
.footer__email {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.25s ease-in-out;
}
.footer__email:hover {
  border-color: #fff;
}
.footer__email:focus {
  outline-offset: 4px;
  outline: 1px dashed #fff;
}

@media all {
  .footer__title {
    margin-bottom: 16px;
  }
  .footer__text1 {
    max-width: 420px;
    margin-bottom: 8px;
  }
  .footer__text2 {
    max-width: 420px;
    margin-bottom: 32px;
  }
  .footer__phone {
    margin-bottom: 8px;
  }
}
@media all and (min-width: 768px) {
  .footer__wrapper {
    padding: 32px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  .footer__col--left {
    flex: 0 0 calc(60% - 16px);
  }
  .footer__col--right {
    flex: 0 0 calc(40% - 16px);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
  }
  .footer__title {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 40px;
  }
  .footer__title span {
    font-size: inherit;
    line-height: inherit;
  }
  .footer__text1 {
    margin-bottom: 0;
  }
  .footer__text2 {
    justify-self: flex-start;
  }
}
@media all and (min-width: 1440px) {
  .footer__wrapper {
    padding: 48px;
  }
  .footer__col {
    flex: 0 0 calc(50% - 16px) !important;
  }
  .footer__title {
    font-size: 52px;
    line-height: 60px;
  }
  .footer__text {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
  }
  .footer__text1 {
    max-width: none;
    text-align: left;
  }
  .footer__text2 {
    margin-bottom: auto;
  }
  .footer__phone {
    font-size: 52px;
    line-height: 60px;
  }
  .footer__email {
    font-size: 36px;
    font-weight: 400;
    line-height: 60px;
  }
}
/* Additional style files. */
[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}