@import "./main-support/main-support.scss";

@mixin mainLink() {
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border 0.25s ease-in-out;

    &:hover {
        border-color: $color-white;
    }

    &:focus {
        outline-offset: 4px;
        outline: 1px dashed $color-white;
    }
}

$block: ".main";
#{$block} {
    background-color: $color-blue-wale;

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-image: url('%=static=%content/main@2x.jpg');
    background-image: image-set(
        url("%=static=%content/main.jpg") 1x,
        url("%=static=%content/main@2x.jpg") 2x
    );

    &__wrapper {
        position: relative;
    }

    &__pic img {
        margin: 0 auto 16px auto;
    }

    &__text {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
    }

    &__title {
        color: $color-white;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
        text-transform: uppercase;

        span {
            display: block;

            font-size: 28px;
            line-height: 32px;
        }
    }

    &__label {
        padding: 16px 4px 8px 4px;

        display: block;

        top: 0;
        left: 32px;
        position: absolute;

        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: $color-lima;
    }

    &__phone {
        color: $color-white;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
    }

    &__email {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    &__link {
        @include mainLink();
    }
}

@media all {
    #{$block} {
        padding-top: 48px;

        &__wrapper {
            padding: 32px 16px;
        }

        &__text1 {
            margin-bottom: 8px;
        }

        &__title {
            margin-bottom: 8px;
        }

        &__text2 {
            margin-bottom: 12px;
        }

        &__support {
            margin-bottom: 16px;
        }

        &__button {
            text-align: center;
            a {
                min-width: 280px;
            }
        }
    }
}

@media all and (max-width: 767px) {
    #{$block} {
        &__pic2 {
            display: none;
        }

        &__label {
            display: none;
        }

        &__row {
            display: none;
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        padding-top: 64px;

        &__wrapper {
            padding: 32px;
        }

        &__pic {
            display: none;
        }

        &__pic2 {
            width: 33.3333333%;


            display: block;

            bottom: 32px;
            right: 48px;
            position: absolute;
        }

        &__label {
            display: block;
        }

        &__title {
            margin-top: 48px;

            font-size: 52px;
            line-height: 45px;
            text-align: left;

            span {
                font-size: 36px;
                line-height: 40px;
            }
        }

        &__text1 {
            max-width: 384px;
            text-align: left;

            top: 0;
            left: 108px;
            position: absolute;
        }

        &__text2 {
            display: none;
        }

        &__button {
            margin-bottom: 32px;

            text-align: left;
        }

        &__phone {
            margin-bottom: 4px;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        padding-top: 96px;

        &__wrapper {
            padding: 48px 0;
        }

        &__title {
            margin-top: 120px;
            margin-bottom: 32px;

            font-size: 90px;
            line-height: 94px;

            span {
                font-size: inherit;
                line-height: inherit;
            }
        }

        &__support {
            margin-bottom: 40px;
        }

        &__label {
            padding: 24px 12px 16px;

            left: 0;
            
            font-size: 41px;
            font-weight: 700;
            line-height: 56px;

            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        &__text1 {
            max-width: 696px;

            top: 32px;
            left: 180px;

            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
        }

        &__pic2 {
            width: 588px;
            
            right: 0;
            top: calc(50% - 258px);
        }

        &__phone {
            font-size: 52px;
            line-height: 60px;
        }

        &__email {
            font-size: 36px;
            line-height: 60px;
        }
    }
}
