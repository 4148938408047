$block: ".pic";
#{$block} {
    display: block;
    max-width: 100%;

    &__img {
        display: inherit;
        max-width: inherit;
    }
}
