@import "./field.scss";

$block: ".form";
#{$block} {
    &__title {
        color: $color-black-russian;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        
        span {
            display: block;

            color: $color-lima;
            font-size: 40px;
            font-weight: 700;
            line-height: 44px;
        }
    }

    &__description,
    &__note {
        color: $color-black-russian;
        font-size: 16px;
        font-weight: 400;
        font-family: Roboto, sans-serif;
        line-height: 18px;
    }

    &__button {
        padding: 8px 24px;

        display: inline-block;

        color: $color-white;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;

        border: 0;
        border-radius: 4px;
        background-color: $color-lima;

        transition: all 0.25s ease-in-out;

        cursor: pointer;

        &:hover {
            background-color: $color-fuego;
        }
    }
}

@media all {
    #{$block} {
        &__title {
            margin-bottom: 16px;
        }

        &__description {
            margin-bottom: 32px;
        }

        &__note {
            margin-bottom: 32px;
        }

        &__fields {
            margin-bottom: 32px;
        }

        &__field {
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
        }

        &__button {
            min-width: 280px;
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__title {
            color: $color-lima;
            font-size: 52px;
            line-height: 45px;
            text-align: center;

            span {
                display: inline;

                font-size: inherit;
                line-height: inherit;
            }
        }

        &__description {
            width: 480px;
            margin: 0 auto 48px auto;

            line-height: 20px;
            text-align: center;
        }

        &__field {
            margin-bottom: 32px;
        }

        &__note {
            line-height: 20px;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__wrapper {
            display: flex;
            align-items: stretch;
            justify-content: center;
        }

        &__col {
            flex: 0 0 calc(600px - 96px);
            margin: 0 48px;
        }

        &__title {
            margin-bottom: 48px;

            color: $color-black-russian;
            font-size: 72px;
            line-height: 72px;

            span {
                display: block;
                
                color: $color-lima;
                font-size: 100px;
                line-height: 93px;
            }
        }

        &__description {
            margin-bottom: 0;

            font-size: 24px;
            line-height: 44px;
            text-align: left;
        }

        &__fields {
            margin-top: 56px;
        }

        &__buttons {
            justify-content: flex-start;
        }

        &__button {
            min-width: 330px;

            padding: 16px 32px;

            font-size: 36px;
            line-height: 48px;
            border-radius: 8px;
        }
    }
}



.thanks,
.error {
    &__title {
        margin-bottom: 8px;

        color: $color-lima;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
    }

    &__description {
        color: $color-black-russian;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
    }
}

@media all and (min-width: 768px) {
    .thanks,
    .error {
        &__title {
            margin-bottom: 24px;

            font-size: 32px;
            line-height: 40px;
        }
    }
}

@media all and (min-width: 1440px) {
    .thanks,
    .error {
        &__title {
            font-size: 48px;
            line-height: 56px;
        }

        &__description {
            font-size: 22px;
            line-height: 26px;
        }
    }
}

.error__title {
    color: #FF294F !important;
}
