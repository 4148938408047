$block: ".how";

#{$block} {
}

@media all {
    #{$block} {
        &__img {
            margin-bottom: 16px;

            img {
                margin: 0 auto;
            }
        }

        &__item {
            margin-bottom: 24px;
            text-align: center;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__list {
            margin: -16px;

            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        &__item {
            flex: 0 0 300px;

            margin: 16px !important;

            text-align: left;
        }

        &__img img {
            margin: 0;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__img {
            margin-bottom: 32px;
        }

        &__list {
            justify-content: space-between;
        }

        &__item {
            flex: 0 0 calc(33.3333333% - 32px);
        }
    }
}


