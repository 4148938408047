* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

body {
    font-family: Circe, 'sans-serif';
}