@import '../normalize.scss';

/* Libraries, which is used in current project. */
@import 'partials/_libraries.scss';

/* Libraries, which is used in current project. */
@import 'built-in-partials/_service.scss';

/* Plugins, which is used in current project. */
@import 'partials/_plugins.scss';

/* Components, which is used in current project. */
@import 'partials/_components.scss';

/* Additional style files. */
@import '../etc/etc.scss';
