@mixin footerLink() {
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border 0.25s ease-in-out;

    &:hover {
        border-color: $color-white;
    }

    &:focus {
        outline-offset: 4px;
        outline: 1px dashed $color-white;
    }
}


$block: ".footer";
#{$block} {
    background-color: $color-lima;

    &__wrapper {
        padding: 32px 16px 64px;
    }

    &__title {
        color: $color-white;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;
        span {
            display: block;

            font-size: 28px;
            line-height: 34px;
        }
    }

    &__text {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }

    &__phone {
        color: $color-white;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;

        @include footerLink();
    }

    &__email {
        color: $color-white;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;

        @include footerLink();
    }
}

@media all {
    #{$block} {
        &__title {
            margin-bottom: 16px;
        }

        &__text1 {
            max-width: 420px;
            margin-bottom: 8px;
        }

        &__text2 {
            max-width: 420px;
            margin-bottom: 32px;
        }

        &__phone {
            margin-bottom: 8px;
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__wrapper {
            padding: 32px;

            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        &__col {
            &--left {
                flex: 0 0 calc(60% - 16px);
            }

            &--right {
                flex: 0 0 calc(40% - 16px);

                display: flex;
                flex-flow: column nowrap;
                align-items: flex-end;
                justify-content: flex-end;

                text-align: right;
            }
        }

        &__title {
            margin-bottom: 24px;

            font-size: 36px;
            line-height: 40px;

            span {
                font-size: inherit;
                line-height: inherit;
            }
        }

        &__text1 {
            margin-bottom: 0;
        }

        &__text2 {
            justify-self: flex-start;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__wrapper {
            padding: 48px;
        }

        &__col {
            flex: 0 0 calc(50% - 16px) !important;
        }

        &__title {
            font-size: 52px;
            line-height: 60px;
        }

        &__text {
            font-size: 24px;
            line-height: 32px;
            text-transform: uppercase;
        }

        &__text1 {
            max-width: none;
            text-align: left;
        }

        &__text2 {
            margin-bottom: auto;
        }

        &__phone {
            font-size: 52px;
            line-height: 60px;
        }

        &__email {
            font-size: 36px;
            font-weight: 400;
            line-height: 60px;
        }
    }
}