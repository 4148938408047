$block: ".section";
#{$block} {
    &--gray {
        background-color: $color-white-smoke;
    }

    &__title {
        color: $color-black-russian;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
    }

    &__pic img {
        margin: 0 auto;
    }
}

@media all {
    #{$block} {
        &__wrapper {
            padding: 32px 16px;
        }

        &__title {
            margin-bottom: 16px;
        }

        &__pic img {
            max-width: 70%;
            margin-bottom: 16px;
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__wrapper {
            padding: 32px;
        }

        &__title {
            margin-bottom: 32px;

            font-size: 36px;
            line-height: 40px;
            text-align: center;
        }

        &__pic img {
            margin-bottom: 24px;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__wrapper {
            padding: 48px 0;
        }

        &__title {
            margin-bottom: 48px;

            font-size: 52px;
            line-height: 60px;
        }

        &__pic img {
            margin-bottom: 32px;
        }
    }
}