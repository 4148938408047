$block: ".item";
#{$block} {
    &__title {
        color: $color-black-russian;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
    }

    &__description {
        color: $color-black-russian;
        font-size: 16px;
        font-weight: 400;
        font-family: Roboto, 'sans-serif';
        line-height: 18px;
    }

    &__item {
        position: relative;

        color: $color-black-russian;
        font-size: 16px;
        font-weight: 400;
        font-family: Roboto, 'sans-serif';
        line-height: 18px;

        list-style-type: none;

        &:after {
            width: 4px;
            height: 4px;

            left: 16px;
            top: 6px;
            position: absolute;

            border-radius: 2px;
            background-color: $color-black-russian;

            content: " ";
        }
    }
}

@media all {
    #{$block} {
        &__title {
            margin-bottom: 8px;
        }
        
        &__item {
            padding-left: 32px;
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__title {
            margin-bottom: 12px;

            font-size: 24px;
            line-height: 26px;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__title {
            margin-bottom: 16px;

            font-size: 28px;
            line-height: 30px;
        }

        &__description,
        &__item {
            font-size: 22px;
            line-height: 26px;
        }
    }
}