$block: ".link";
#{$block} {
    &__item {
        padding: 8px 32px;

        display: inline-block;

        color: $color-white;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        text-decoration: none;

        border-radius: 5px;
        background-color: $color-lima;

        transition: all 0.25s ease-in-out;

        cursor: pointer;


        &:hover {
            background-color: $color-fuego;
        }
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        &__item {
            border-radius: 8px;
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__item {
            font-size: 36px;
            line-height: 60px;
        }
    }
}
