@mixin font ($font-family, $font-src, $font-weight, $font-style: normal) {
    @font-face {
      font-family: $font-family;
      font-weight: $font-weight;
      font-style: $font-style;
      font-display: swap;
      src:  url("../fonts/#{$font-src}.woff2") format("woff2"),
            url("../fonts/#{$font-src}.woff") format("woff");
    }
  }
  