$block: ".main-after";
#{$block} {
    background-color: $color-lima;

    &__item {
    }
}

@media all and (max-width: 767px) {
    #{$block} {
        display: none;
    }
}

@media all and (min-width: 768px) {
    #{$block} {
        padding: 8px 32px 12px 32px;

        &__item {
            display: none;

            &--key {
                display: block;

                .item__title {
                    display: none;
                }

                .item__title,
                .item__item,
                .item__description {
                    color: $color-white;
                    text-align: center;
                }
            }
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        padding: 40px 0;

        &__list {
            margin: 0 -16px;

            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        &__item {
            margin: 0 16px;

            flex: 0 0 calc(50% - 32px);

            display: block;

            .item__title,
            .item__item,
            .item__description {
                display: block;
                color: $color-white;
            }

            .item__item:after {
                background-color: $color-white;
            }

            &--key {
                .item__title,
                .item__description {
                    text-align: left;
                }
            }
        }
    }
}
