@mixin menuLink() {
    display: inline-block;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border 0.25s ease-in-out;

    &:hover {
        border-color: $color-white;
    }

    &:focus {
        outline-offset: 4px;
        outline: 1px dashed $color-white;
    }
}

$block: ".header-menu";
#{$block} {

    &__item {
        list-style-type: none;
    }

    &__link {
        color: $color-white;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        text-decoration: none;
    }

    &__phone {
        color: $color-white;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        
        @include menuLink();
    }

    &__email {
        color: $color-white;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;

        @include menuLink();
    }
}

@media all and (max-width: 767px) {
    #{$block} {
        padding: 32px 16px;
        top: 48px;
    }
}

@media all and (min-width: 768px) and (max-width: 1439px) {
    #{$block} {
        padding: 64px 32px;
        top: 64px;
    }
}

@media all and (max-width: 1439px) {
    #{$block} {
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100;
        position: fixed;

        transform: translateX(-100%);
        background-color: $color-black-russian;
        transition: all 0.5s ease-in-out;

        &--is-active {
            display: block;
            transform: translateX(0);
        }

        &__list {
            margin-bottom: 32px;
        }

        &__item {
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media all and (min-width: 1440px) {
    #{$block} {
        &__row {
            display: none;
        }

        &__list {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        &__item {
            margin-right: 32px;
            &:last-of-type {
                margin-right: 0;
            }
        }

        &__link {
            color: $color-white;
            font-size: 18px;
            font-weight: 500;
            font-family: Roboto, sans-serif;
            line-height: 21px;
            text-transform: uppercase;
            transition: all 0.25s ease-in-out;

            &:hover {
                color: $color-lima;
            }
        }
    }
}